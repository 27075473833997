import { createAction, props } from '@ngrx/store';
import { DaySpreadsDto, DaySpreadsCategory } from '@features/spreads/components/day-spreads/day-spreads.model';
import { AirportGap, CommercialWindows, DaySpreadGap, ResponseTimeSpread, TimeSpread } from '../spreads.models';

export const daySpreadsStarted = createAction('[Day Spreads screen] Day Spreads screen started');
export const daySpreadsAPIFetchingSuccess = createAction(
  '[Day Spreads API] Day Spreads fetched successfully using API',
  props<{ daySpreads: DaySpreadsDto }>()
);
export const daySpreadsFileImportedSuccess = createAction('[Day Spreads API] Day Spreads file imported successfully');
export const daySpreadsAPIFetchingFailed = createAction('[Day Spreads API] Day Spreads fetching failed using API');

export const daySpreadsCategoryUpdatingStarted = createAction(
  '[Day Spreads Screen] Day Spreads updating started',
  props<{ daySpreadsCategory: DaySpreadsCategory; categoryId: number }>()
);
export const daySpreadsCategoryUpdatingAPISuccess = createAction(
  '[Day Spreads API] Day Spreads updated successfully using API'
);
export const daySpreadsCategoryEditingAPIFailed = createAction(
  '[Day Spreads API] Day Spreads updating failed using API'
);

export const daySpreadsDaysUpdatingStarted = createAction(
  '[Day Spreads Screen] Day Spreads days updating started',
  props<{ previous_days: number[]; new_days: number[] }>()
);
export const daySpreadsDaysUpdatingAPISuccess = createAction(
  '[Day Spreads API] Day Spreads days updated successfully using API'
);
export const daySpreadsDaysUpdatingAPIFailed = createAction(
  '[Day Spreads API] Day Spreads days updating failed using API'
);

export const daySpreadsDeletingStarted = createAction(
  '[Day Spreads Screen] Day Spreads deleting started',
  props<{ days: number[] }>()
);
export const daySpreadsDeletingAPISuccess = createAction(
  '[Day Spreads API] Day Spreads deleted successfully using API'
);
export const daySpreadsDaysDeletingAPIFailed = createAction('[Day Spreads API] deleting failed using API');

/* Time Spreads Table */

export const timeSpreadsStarted = createAction('[Time Spreads] Time Spreads screen started');

export const timeSpreadsApiFetchedSuccess = createAction(
  '[Time Spreads API] Time Spreads api fetched success',
  props<{ timeSpreads: ResponseTimeSpread[] }>()
);

export const timeSpreadsApiFetchedFailed = createAction('[Time Spreads API] Time Spreads api fetched success failed');

export const timeSpreadsAddingStarted = createAction(
  '[Time Spreads] Time Spread adding started',
  props<{ timeSpread: TimeSpread }>()
);

export const timeSpreadsApiAddingSuccess = createAction(
  '[Time Spreads API] Time Spreads adding api success',
  props<{ timeSpreads: ResponseTimeSpread[] }>()
);

export const timeSpreadsApiAddingFailed = createAction('[Time Spreads API] Time Spreads adding api Failed');

export const editTimeSpreadStarted = createAction(
  '[Time Spreads] Edit Time Spread started',
  props<{ timeSpread: TimeSpread; id: number }>()
);

export const editTimeSpreadApiSuccess = createAction(
  '[Time Spreads API] Edit Time Spread api success',
  props<{ timeSpreads: ResponseTimeSpread[] }>()
);

export const editTimeSpreadsApiFailed = createAction('[Time Spreads API ] Edit Time Spread api failed');

/* City Gaps */

export const cityGapsStarted = createAction('[City Gaps] City Gaps screen started');

export const cityGapsApiFetchedSuccess = createAction(
  '[City Gaps API] City Gaps api fetched success',
  props<{ cityGaps: DaySpreadGap[] }>()
);

export const cityGapsApiFetchedFailed = createAction('[City Gaps API] City Gaps api fetched success failed');

export const addCityGapButtonClicked = createAction(
  '[City Gaps] Add city gap button clicked',
  props<{ cityGap: DaySpreadGap }>()
);

export const apiAddCityGapSuccess = createAction(
  '[City Gaps API] City Gaps adding api success',
  props<{ cityGaps: DaySpreadGap[] }>()
);

export const apiAddCityGapsFailed = createAction('[City Gaps API] City Gaps adding api Failed');

export const editCityGapButtonClickedStarted = createAction(
  '[City Gaps] Edit City gap button clicked',
  props<{ cityGap: DaySpreadGap; frequency?: boolean }>()
);

export const apiEditCityGapSuccess = createAction(
  '[City Gaps API] Edit City gap api success',
  props<{ cityGaps: DaySpreadGap[] }>()
);

export const apiEditCityGapFailed = createAction('[City Gaps API ] Edit City gap api failed');

/* Airport Gaps */

export const airportGapsStarted = createAction('[Airport Gaps] Airport Gaps screen started');

export const airportGapsApiFetchedSuccess = createAction(
  '[Airport Gaps API] Airport Gaps api fetched success',
  props<{ airportGaps: AirportGap[] }>()
);

export const airportGapsApiFetchedFailed = createAction('[Airport Gaps API] Airport Gaps api fetched success failed');

export const addAirportGapButtonClicked = createAction(
  '[Airport Gaps] Add airport gap button clicked',
  props<{ airportGap: AirportGap }>()
);

export const apiAddAirportGapSuccess = createAction(
  '[Airport Gaps API] Airport Gaps adding api success',
  props<{ airportGaps: AirportGap[] }>()
);

export const apiAddAirportGapsFailed = createAction('[Airport Gaps API] Airport Gaps adding api Failed');

export const editAirportGapButtonClickedStarted = createAction(
  '[Airport Gaps] Edit Airport gap button clicked',
  props<{ airportGap: AirportGap; frequency?: boolean }>()
);

export const apiEditAirportGapSuccess = createAction(
  '[Airport Gaps API] Edit Airport gap api success',
  props<{ airportGaps: AirportGap[] }>()
);

export const apiEditAirportGapFailed = createAction('[Airport Gaps API ] Edit Airport gap api failed');

/* Commercial Windows */

export const commercialWindowsStarted = createAction('[Commercial Windows] Commercial Windows screen started');

export const commercialWindowsApiFetchedSuccess = createAction(
  '[Commercial Windows API] Commercial Windows api fetched success',
  props<{ commercialWindows: CommercialWindows[] }>()
);

export const commercialWindowsApiFetchedFailed = createAction(
  '[Commercial Windows API] Commercial Windows api fetched success failed'
);

export const commercialWindowsAddingStarted = createAction(
  '[Commercial Windows] Commercial Window adding started',
  props<{ commercialWindow: CommercialWindows }>()
);

export const commercialWindowsApiAddingSuccess = createAction(
  '[Commercial Windows API] Commercial Window adding api success',
  props<{ commercialWindow: CommercialWindows }>()
);

export const commercialWindowsApiAddingFailed = createAction(
  '[Commercial Windows API] Commercial Window adding api Failed'
);

export const commercialWindowsValidationFailed = createAction(
  '[Commercial Windows] Commercial Window validation failed',
  props<{ commercialWindow: CommercialWindows }>()
);

export const editCommercialWindowStarted = createAction(
  '[Commercial Windows] Edit Commercial Window started',
  props<{ commercialWindow: CommercialWindows }>()
);

export const editCommercialWindowApiSuccess = createAction(
  '[Commercial Windows API] Edit Commercial Window api success',
  props<{ commercialWindow: CommercialWindows }>()
);

export const editCommercialWindowApiFailed = createAction(
  '[Commercial Windows API ] Edit Commercial Window api failed'
);

export const deleteCommercialWindowStarted = createAction(
  '[Commercial Windows] Delete Commercial Window started',
  props<{ commercialWindowId: number }>()
);

export const deleteCommercialWindowApiSuccess = createAction(
  '[Commercial Windows API] Delete Commercial Window api success',
  props<{ commercialWindowId: number }>()
);

export const deleteCommercialWindowApiFailed = createAction(
  '[Commercial Windows API ] Delete Commercial Window api failed'
);
